import { type Config } from 'tailwindcss'

export const extendedTheme = {
	colors: {
		border: {
			DEFAULT: 'hsl(var(--border), <alpha-value>)',
		},
		// button: 'hsl(var(--border-button), <alpha-value>)',
		brand: {
			start: 'hsla(var(--brand-gradient-start), <alpha-value>)',
			end: 'hsla(var(--brand-gradient-end), <alpha-value>)',
		},
		input: {
			DEFAULT: 'hsl(var(--input), <alpha-value>)',
			invalid: 'hsl(var(--input-invalid), <alpha-value>)',
		},
		ring: {
			DEFAULT: 'hsl(var(--ring), <alpha-value>)',
			invalid: 'hsl(var(--foreground-destructive), <alpha-value>)',
		},
		background: 'hsl(var(--background), <alpha-value>)',
		foreground: {
			DEFAULT: 'hsl(var(--foreground), <alpha-value>)',
			destructive: 'hsl(var(--foreground-destructive), <alpha-value>)',
		},
		primary: {
			DEFAULT: 'hsl(var(--primary), <alpha-value>)',
			foreground: 'hsl(var(--primary-foreground), <alpha-value>)',
		},
		secondary: {
			DEFAULT: 'hsl(var(--secondary), <alpha-value>)',
			foreground: 'hsl(var(--secondary-foreground), <alpha-value>)',
		},
		destructive: {
			DEFAULT: 'hsl(var(--destructive), <alpha-value>)',
			foreground: 'hsl(var(--destructive-foreground), <alpha-value>)',
		},
		muted: {
			DEFAULT: 'hsl(var(--muted), <alpha-value>)',
			foreground: 'hsl(var(--muted-foreground), <alpha-value>)',
		},
		accent: {
			DEFAULT: 'hsl(var(--accent), <alpha-value>)',
			foreground: 'hsl(var(--accent-foreground), <alpha-value>)',
			hover: 'hsl(var(--accent-gray-hover), <alpha-value>)',
			red: 'hsl(var(--accent-red), <alpha-value>)',
			green: 'hsl(var(--accent-green), <alpha-value>)',
			orange: 'hsl(var(--accent-orange), <alpha-value>)',
		},
		popover: {
			DEFAULT: 'hsl(var(--popover), <alpha-value>)',
			foreground: 'hsl(var(--popover-foreground), <alpha-value>)',
		},
		card: {
			DEFAULT: 'hsl(var(--card), <alpha-value>)',
			foreground: 'hsl(var(--card-foreground), <alpha-value>)',
		},
		text: {
			DEFAULT: 'hsl(var(--text), <alpha-value>)',
			gray: 'hsl(var(--text-gray), <alpha-value>)',
		},
		switch: {
			background: 'hsl(var(--switch-background), <alpha-value>)',
			active: 'hsl(var(--switch-active), <alpha-value>)',
			thumb: 'hsl(var(--switch-thumb), <alpha-value>)',
		},
		table: {
			background: 'hsl(var(--table-background), <alpha-value>)',
			foreground: 'hsl(var(--table-foreground), <alpha-value>)',
			hover: 'hsl(var(--table-hover), <alpha-value>)',
		},
		tableHeader: {
			background: 'hsl(var(--table-header-background), <alpha-value>)',
			foreground: 'hsl(var(--table-header-foreground), <alpha-value>)',
		},
		purple: {
			DEFAULT: 'hsl(var(--tint-purple), <alpha-value>)',
		},
	},
	borderColor: {
		DEFAULT: 'hsl(var(--border), <alpha-value>)',
		table: 'hsl(var(--table-border))',
		button: 'hsl(var(--border-button))',
		assessment: 'hsl(var(--border-assessment))',
	},
	borderWidth: {
		'3': '3px',
	},
	borderRadius: {
		lg: 'var(--radius)',
		md: 'calc(var(--radius) - 2px)',
		sm: 'calc(var(--radius) - 4px)',
	},
	boxShadow: {
		glow: '0 0 15px hsl(var(--primary), <alpha-value>)', // Customize the glow color and intensity
		'glow-lg':
			'0 0px 15px -3px hsl(var(--primary), 0.5), 0 4px 6px -2px hsl(var(--primary), 0.3)',
	},
	fontFamily: {
		sans: ['Euclid Square', 'ui-sans-serif', 'system-ui'],
		serif: ['Euclid Square', 'ui-serif', 'Georgia'],
		mono: ['Euclid Square', 'ui-monospace', 'SFMono-Regular'],
	},
	fontSize: {
		// 1rem = 16px
		/** 80px size / 84px high / bold */
		mega: ['5rem', { lineHeight: '5.25rem', fontWeight: '700' }],
		/** 56px size / 62px high / bold */
		h1: ['3.5rem', { lineHeight: '3.875rem', fontWeight: '700' }],
		/** 40px size / 48px high / bold */
		h2: ['2.5rem', { lineHeight: '3rem', fontWeight: '700' }],
		/** 32px size / 36px high / bold */
		h3: ['2rem', { lineHeight: '2.25rem', fontWeight: '700' }],
		/** 28px size / 36px high / bold */
		h4: ['1.75rem', { lineHeight: '2.25rem', fontWeight: '700' }],
		/** 24px size / 32px high / bold */
		h5: ['1.5rem', { lineHeight: '2rem', fontWeight: '700' }],
		/** 16px size / 20px high / bold */
		h6: ['1rem', { lineHeight: '1.25rem', fontWeight: '600' }],

		xs: '0.625rem',
		sm: '0.75rem',
		base: '0.875rem',
		lg: '1rem',
		xl: '1.125rem',
		'2xl': '1.25rem',
		'3xl': '1.375rem',
		'4xl': '1.5rem',
		'5xl': '1.75rem',

		/** 18px size / 24px high / semibold */
		caption: ['1.125rem', { lineHeight: '1.5rem', fontWeight: '600' }],
		/** 12px size / 16px high / bold */
		button: ['0.75rem', { lineHeight: '1rem', fontWeight: '700' }],
	},
	keyframes: {
		'accordion-down': {
			from: { height: '0' },
			to: { height: 'var(--radix-accordion-content-height)' },
		},
		'accordion-up': {
			from: { height: 'var(--radix-accordion-content-height)' },
			to: { height: '0' },
		},
		progress: {
			'0%, 100%': {
				'background-size': '200% 100%',
				'background-position': 'left center',
			},
			'50%': {
				'background-size': '200% 100%',
				'background-position': 'right center',
			},
		},
	},
	animation: {
		'accordion-down': 'accordion-down 0.2s ease-out',
		'accordion-up': 'accordion-up 0.2s ease-out',
		progress: 'progress 2s ease infinite',
		pulse: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
	},
} satisfies Config['theme']
